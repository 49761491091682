
let _debug = true

export function debug(on) {
	_debug = on
}

export function maybe(a, f) {
	if (a) return f(a)
	else return null
}

export function apply(a, f) {
	f(a)
	return a
}

export function dfault(a, d) { 
	return a === undefined ? d : a 
}

export function def(a) { 
	return a === undefined ? null : a 
}

export function otherwise(a, b) {
	return a ? a : b
}

export function orDfault(a, f, d) {
	return a ? f(a) : d
}

export function arr(from, to) { 
	return from && from.length === 0 && to && to.length === 0 ? from : to 
}

export function max(a, b) {
	return Math.max(a, b)
}

export function min(a, b) {
	return Math.min(a, b)
}

export function rgb(r, g, b) { 
	return `rgb(${r},${g},${b})` 
}

export function rgba(r, g, b, a) { 
	return `rgb(${r},${g},${b},${a})` 
}

export function log(s) {
	if (_debug) console.log(s)
}

export function report(s) {
	console.log(s)
}

export function history(path) {
	let path0 = window.location.pathname
	if (path !== path0) {
		log('History: ' + path)
		window.history.pushState({}, '', path)
	}
}

export function historyHash(hash) {
	window.history.pushState({}, '', window.location.pathname + '#' + hash)
}

export function setPath(path) {
	window.history.replaceState({}, '', path)
}

export function nohash() {
	window.history.replaceState({}, '', window.location.pathname)
}

export function localStorageBool(key, dfault) {
	let item = localStorage.getItem(key)
	return item ? item === 'true' : dfault
}

export function localStorageInt(key, dfault) {
	let item = localStorage.getItem(key)
	return item ? parseInt(item) : dfault
}

export function ms() {
	return Date.now()
}
