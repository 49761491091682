import { ms } from './Util'

export default class Animator {

	constructor(source, velocity, component) {
		this.source = source
		this.velocity = velocity
		this.component = component
		this.value = source
	}

	unmount() {
		if (this.interval) {
			clearInterval(this.interval)
			this.interval = null
		}
		if (this.timeout) {
			clearTimeout(this.timeout)
			this.timeout = null
		}
	}

	inverse() { 
		return 1 - this.value
	}

	not() { 
		return this.inverse()
	}

	set(value) {
		this.value = value
		if (this.interval) clearInterval(this.interval)
	}
  
	play(target, then) {
		if (this.timeout) clearTimeout(this.timeout)
		this.source = this.value
		this.target = target
		this.startMs = ms()
		if (!this.interval) 
			this.interval = window.setInterval(() => this._onTick(ms(), then), 40)
	}

	playAfter(ms, target, then) {
		if (this.timeout) clearTimeout(this.timeout)
		this.timeout = setTimeout(() => this.play(target, then), ms)
	}
  
	_onTick(ms, then) {
		let usedMs = ms - this.startMs
		let remainingMs = this.velocity - usedMs
		if (remainingMs > 0) {
			let diff = this.target - this.source
			this.value = this.source + (diff * (usedMs / this.velocity)) 
		}
		else {
			if (this.interval) {
				clearInterval(this.interval)
				this.interval = null
				this.value = this.target
				if (then) then()
			}
		}
		this.component.setState(this.component.state)
	}

	max() {
		return this.value === 1
	}

	increasing() {
		return this.value < this.target
	}

	decreasing() {
		return this.value > this.target
	}
  
}

export var off = new Animator(0, 0, null)
export var on = new Animator(1, 0, null)