import React, { Component } from 'react'
import { ms } from './Util'
import imgLogo from './img/logo.svg'
import Animator from './Animator'

const MIN_MS = 1000
const FADE_MS = 250

export default class SplashWidget extends Component {

	constructor() {
		super()
		this.state = {
			invisible: new Animator(0, FADE_MS, this)
		}
	}

	componentDidMount() {
		let t0 = ms()
		this.props.onInit().then(() => {
			let d = ms() - t0
			let remaining = MIN_MS - d
			let f = () => this.state.invisible.play(1, () => this.props.onReady())  
			if (remaining > 0) setTimeout(f, remaining)
			else f()
		})
	}

	// View

	render() {
		let state = this.state
		return (<div
			style={{
				position: 'absolute',
				inset: 0,
				backgroundImage: `url(${imgLogo})`,
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 40,
				opacity: state.invisible.inverse()
			}}>
		</div>)
	}

}
