
export default class Db {

	_db = null

	async init() {
		return new Promise(async (resolve, reject) => {
			let open = window.indexedDB.open("main", 1);
			open.onupgradeneeded = event => {
				let db = event.target.result
				let names = db.objectStoreNames
				if (!names.contains('setting')) db.createObjectStore('setting')
				if (!names.contains('blog-before-id')) db.createObjectStore('blog-before-id')
			}
			open.onsuccess = event => {
				this._db = event.target.result
				resolve()
			}
			open.onerror = event => {
				console.error('Could not open DB')
				reject()
			}
		})
	}

	async loadBlogBeforeIds() {
		return new Promise(async (resolve, reject) => {
			let transaction = this._db.transaction('blog-before-id')
			let blogBeforeIds = transaction.objectStore("blog-before-id")
			let open = blogBeforeIds.openCursor()
			let all = {}
			open.onsuccess = event => {
				let cursor = event.target.result;
				if (cursor) {
					let key = cursor.primaryKey;
					let value = cursor.value;
					all[key] = value
					cursor.continue();
				}
				else resolve(all)
			}
			open.onerror = () => {
				console.error('Could not read from DB')
				reject()
			}
		})
	}

	async saveBlogBeforeId(blog, beforeId) {
		return new Promise(async (resolve, reject) => {
			let transaction = this._db.transaction('blog-before-id', 'readwrite')
			let blogBeforeIds = transaction.objectStore('blog-before-id')
			blogBeforeIds.put(beforeId, blog)
			transaction.oncomplete = event => {
				resolve()
			}
			transaction.onerror = () => {
				console.error('Could not write to DB')
				reject()
			}
		})
	}

}
