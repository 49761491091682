import { prefix } from 'inline-style-prefixer'

export function Row() {
    return prefix({
        display: "flex",
        flexWrap: "nowrap" 
    })
}

export function Stretch(spacing) {
    return ({
        display: "flex",
        flexWrap: "nowrap",
        marginTop: spacing,
        marginLeft: spacing
    })
}

export function Grow(spacing, grow) {
    return prefix({
        flexGrow: grow,
        marginTop: spacing,
        marginLeft: spacing
    })
}
