import React, { Component } from 'react'
import Animator from './Animator'
import { rgb } from './Util'
import imgTl from './img/tl.svg'
import imgTr from './img/tr.svg'
import imgBl from './img/bl.svg'
import imgBr from './img/br.svg'
import imgT from './img/t.svg'
import imgL from './img/l.svg'
import imgB from './img/b.svg'
import imgR from './img/r.svg'

export default class TileWidget extends Component {

  constructor(props) {
    super(props)
    this.state = {
      opacity: new Animator(0, 500, this)
    }
  }

  componentWillUnmount() {
    this.state.opacity.unmount()
  }

  // Events

  _onLoad() {
    let state = this.state
    state.opacity.play(1.0)
    this.setState(state)
  }

  _onClick() {
    let props = this.props
    if (props.onClick) props.onClick()
  }

  // View

  render() {
    let props = this.props
    let state = this.state
    let tile = props.tile
    let image = tile.image
    let color = image.color
    let opacity = state.opacity
    let border = 9
    return (
      <div
        className="ImageContainer"
        style={{
          position: 'relative',
          flexGrow: 1,
          cursor: 'pointer',
          width: tile.width,
          height: tile.height,
          backgroundColor: rgb(color, color, color)
        }}
        ref={tile.ref}
        onClick={() => this._onClick()}>
        <img
          className="ImageImage"
          style={{
            width: '100%',
            height: '100%',
            opacity: opacity.value
          }}
          src={image.url}
          alt=""
          onLoad={() => this._onLoad()}/>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: border,
            height: border,
            backgroundImage: `url(${imgTl})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'norepeat',
            pointerEvents: 'none'
          }} />
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: border,
            height: border,
            backgroundImage: `url(${imgTr})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'norepeat',
            pointerEvents: 'none'
          }} />
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: border,
            height: border,
            backgroundImage: `url(${imgBr})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'norepeat',
            pointerEvents: 'none'
          }} />
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: border,
            height: border,
            backgroundImage: `url(${imgBl})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'norepeat',
            pointerEvents: 'none'
          }} />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: border,
            right: border,
            height: border,
            backgroundImage: `url(${imgT})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'repeat-x',
            pointerEvents: 'none'
          }} />
        <div
          style={{
            position: 'absolute',
            top: border,
            right: 0,
            bottom: border,
            width: border,
            backgroundImage: `url(${imgR})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'repeat-y',
            pointerEvents: 'none'
          }} />
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: border,
            right: border,
            height: border,
            backgroundImage: `url(${imgB})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'repeat-x',
            pointerEvents: 'none'
          }} />
        <div
          style={{
            position: 'absolute',
            top: border,
            left: 0,
            bottom: border,
            width: border,
            backgroundImage: `url(${imgL})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'repeat-y',
            pointerEvents: 'none'
          }} />
      </div>)
  }

}
