import { log, otherwise } from './Util'

export default class BaseServer {

	_authorization = null

	constructor(authorization) {
		this.authorization = authorization
	}

	authorize(authorization) {
		this._authorization = authorization
	}

	unauthorize() {
		this._authorization = null
	}

	async request(method, url, data) {
		let authorization = otherwise(this._authorization, '')
		const fetchOptions = {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': authorization
			},
			cache: 'no-cache',
			method: method
		}
		log(method + ' ' + url)
		log('Authorization: ' + authorization)
		if (data) {
			const json = JSON.stringify(data)
			log('Data: ' + json)
			fetchOptions['body'] = json
		}
		try {
			let response = await fetch(url, fetchOptions)
			let text = await response.text()
			let ok = response.ok
			if (ok) log("Response: Ok")
			else {
				log("Failed: " + response.status)
				let status = response.status
				if (status === 400) throw new Error('client')
				if (status === 401) throw new Error('unauthorized')
				if (status === 500) throw new Error('server-error')
			}
			log('Response: ' + text.substr(0, 100))
			return {
				ok,
				obj: text ? JSON.parse(text) : null
			}
		}
		catch(e) {
			log('Error: ' + e)
			throw e
		}
	}

	get(url) { return this.request('GET', url) }

	post(url, data) { return this.request('POST', url, data) }

	put(url, data) { return this.request('PUT', url, data) }

	del(url, data) { return this.request('DELETE', url, data) }

	enc(s) { return encodeURIComponent(s) }

}