import React, { Component } from 'react'
import Animator, { off } from './Animator'
import { maybe, dfault, orDfault } from './Util'

let DISABLED_OPACITY = 0.4

export class ActionButton {

  constructor(component) {
    this.disabled = new Animator(0, 200, component)
    this.disappeared = new Animator(0, 200, component)
    this.toggled = new Animator(0, 200, component)
  }

  unmount() {
    this.disabled.unmount()
    this.disappeared.unmount()
    this.toggled.unmount()
  }

  disable() {
    this.disabled.play(1)
  }

  enable() {
    this.disabled.play(0)
  }

  setEnabled() {
    this.disabled.set(0)
  }

  disappear() {
    this.disappeared.play(1)
  }

  appear() {
    this.disappeared.play(0)
  }

  setUntoggled() {
    this.toggled.set(0)
  }

  toggle() {
    this.toggled.play(1)
  }

  untoggle() {
    this.toggled.play(0)
  }

  toggleThenUntoggle() {
    this.toggled.play(1)
    this.toggled.playAfter(3000, 0)
    this.disabled.playAfter(3000, 0)
  }

}

export class ActionButtonWidget extends Component {

  componentWillUnmount() {
    maybe(this.props.actionButton, it => it.unmount())
  }

  render() {
    let props = this.props
    let actionButton = props.actionButton
    let disabled = orDfault(actionButton, it => it.disabled, off)
    let disappeared = orDfault(actionButton, it => it.disappeared, off)
    let toggled = orDfault(actionButton, it => it.toggled, off)
    let image = props.image
    let imageUrl = props.imageUrl
    let toggleImage = props.toggleImage
    let size = dfault(props.size, 50) 
    let link = props.link
    let onClick = props.onClick
    let toggleOnClick = props.toggleOnClick
    return (
      <div
        className="ActionButtonContainer" 
        style={{
          position: 'relative',
          width: size,
          height: size
        }}>
        <a
          className="ActionButtonLink" 
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
          href={link} 
          onClick={e => e.preventDefault()}>
          <div 
            className={`ActionButton ${props.className}`}
            style={{
              backgroundColor: '#00000090',
              borderRadius: '50%',
              boxShadow: '0px 0px 10px 4px rgba(0, 0, 0, 0.20)',
              cursor: 'pointer',
              width: size * disappeared.inverse(),
              height: size * disappeared.inverse(),
              opacity: 1 - (disabled.value * (1 - DISABLED_OPACITY)),
              pointerEvents: 'auto'
            }}
            onClick={() => 
              disabled.not() && 
              disappeared.not() && 
              onClick ? 
                (toggled.value ? toggleOnClick() : onClick()) : 
                null}>
            {toggled.max() ?
              null :
              <div
                className="ActionButtonImage"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  borderRadius: '50%',
                  backgroundImage: `url(${image || imageUrl})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  pointerEvents: 'none',
                  opacity: toggled.inverse()
                }}/> 
            }
            {toggled.value ?
              <div
                className="ActionButtonImage"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  borderRadius: '50%',
                  backgroundImage: `url(${toggleImage})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  pointerEvents: 'none',
                  opacity: toggled.value
                }}/> :
                null
            }
          </div>
        </a>
     </div>)
  }

}
