import { debug } from './Util'
import React from 'react'
import {Routes, Route, BrowserRouter} from 'react-router-dom'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import ReactGA from 'react-ga';
import { useParams } from "react-router-dom"
import { useLocation } from "react-router-dom"

debug(process.env.NODE_ENV === 'development')

const AppWithParams = (props) => 
    <App {...props} params={useParams()} location={useLocation()}/>

ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route exact path='/' element={<AppWithParams/>}/>
            <Route path='/blogs/:blog' element={<AppWithParams/>}/>
            <Route path='/blogs/:blog/:beforeId' element={<AppWithParams/>}/>
            <Route path='/dash' element={<AppWithParams/>}/>
            <Route path='/dash/:beforeId' element={<AppWithParams/>}/>
        </Routes>
    </BrowserRouter>, 
    document.getElementById('root'));
ReactGA.initialize('UA-73275029-1');
ReactGA.pageview('/index')
serviceWorker.unregister();
