import React from 'react'
import { Component } from 'react'
import { ActionButtonWidget } from './ActionButtonWidget'
import Animator from './Animator'
import { maybe, dfault } from './Util'
import imgOpen from "./img/open.svg";

export class ActionButtonGroup {

  constructor(component, actionButtons, open) {
    this.actionButtons = actionButtons
    this.closed = new Animator(0, 200, component)
    this.actionButtons.forEach(it => it.disappeared = this.closed)
    this.closed.set(open ? 0 : 1)
  }

  unmount() {
    this.closed.unmount()
    this.actionButtons.forEach(it => it.unmount())
  }

  close() {
    this.closed.play(1)
  }

  open() {
    this.closed.play(0)
  }

}

export class ActionButtonGroupWidget extends Component {

  componentWillUnmount() {
    maybe(this.props.actionButtonWidget, it => it.unmount())
  }

  // Events

  _onOpenClose() {
    let state = this.state
    let props = this.props
    let actionButtonGroup = props.actionButtonGroup
    let closed = actionButtonGroup.closed
    if (closed.value) {
      if (!closed.increasing()) {
        actionButtonGroup.open()
        props.onOpen()
      }
    }
    else {
      actionButtonGroup.close()
      props.onClose()
    }
    this.setState(state)
  }

  // View

  render() {
    let props = this.props
    let className = dfault(props.className, '')
    let top = dfault(props.top, 0)
    let mainActionButtonWidgets = 
      dfault(props.mainActionButtonWidgets, [])
    let secondaryActionButtonWidgets = 
      dfault(props.secondaryActionButtonWidgets, [])
    return (
      <div
        style={{
          pointerEvents: 'none'
        }}>
        <div
          className={`ActionButtons ${className}`}
          style={{
              position: 'fixed',
              top: 10 + top,
              right: 10
          }}>
          {secondaryActionButtonWidgets.map((actionButtonWidget, index) =>
            <div 
              key={index}
              style={{
                marginBottom: 10
              }}>
              {actionButtonWidget}
            </div>)}
        </div>
        <div
          className={`ActionButtons ${className}`}
          style={{
              position: 'fixed',
              bottom: 10,
              right: 10
          }}>
          {mainActionButtonWidgets.map((actionButtonWidget, index) =>
            <div 
              key={index}
              style={{
                marginBottom: 10
              }}>
              {actionButtonWidget}
            </div>)}
          <ActionButtonWidget
            image={imgOpen}
            onClick={() => this._onOpenClose()}/>
        </div>
      </div>)
  }

}
