
export var AppTextHeading = 'ABOUT'
export var AppTextP1 = `
    Cascadr is a Tumblr image viewer. It's a different way to view Tumblr. 
    Cascadr allows you to see the images that have been posted to any blog in a 
    variable-sized horizontal grid. This allows you to easily and quickly scan through a 
    blog and find the images that you want.
    `
export var AppTextP2 = `
    Cascadr is ideal for exploring Tumblr. Quickly scan image thumbnails and click 
    to see a full-size version. From the full-size version you can reblog the post or 
    click through to the previous poster and continue to explore. With Cascadr there 
    is no limit to what you can find.
    `
export var AppFeedbackHeading = 'FEEDBACK'
export var AppFeedbackP1 = `
    Found a bug? Want to suggest a feature? Please post any feedback on Twitter to 
    `
export var AppVersionHeading = 'VERSION'
export var AppVersionP1 = `
    14.0
    ` 