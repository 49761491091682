import Db from "./Db";

export default class Persist {

	_db = new Db()
	_blogBeforeIds = {}

	async init() {
		await this._db.init()
		let blogBeforeIds = await this._db.loadBlogBeforeIds()
		this._blogBeforeIds = {...localStorage, ...blogBeforeIds}
	}

	setBlogBeforeId(blog, beforeId) {
		this._blogBeforeIds[blog] = beforeId
		this._db.saveBlogBeforeId(blog, beforeId)
	}

	getBlogBeforeId(blog) { 
		return this._blogBeforeIds[blog]
	}

}
