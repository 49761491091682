import BaseServer from './BaseServer'

export default class Server extends BaseServer {

	_url = null

	constructor(url, authorization) {
		super(authorization)
		this._url = url
	}

	url(path) { return this._url + path }

	getBlog(name) {
		return this.get(this.url('/blogs/' + name))
	}

	getBlogImages(name, beforeId, num) { 
		return this.get(this.url('/blogs/' + name + '/images?beforeId=' + beforeId + '&num=' + num)) 
	}

	getRequestToken() {
		return this.get(this.url('/users/requesttoken'))
	}

	getDashImages(beforeId, num) { 
		return this.get(this.url('/users/me/dash/images?beforeId=' + beforeId + '&num=' + num)) 
	}

	like(postId, reblogKey) {
		return this.post(this.url('/users/me/likes'), { postId, reblogKey })
	}

	unlike(postId, reblogKey) {
		return this.del(this.url('/users/me/likes'), { postId, reblogKey })
	}

	reblog(postId, reblogKey) {
		return this.post(this.url('/users/me/posts/reblog'), { postId, reblogKey })
	}

	follow(blogName) {
		return this.post(this.url('/users/me/following'), { blogName })
	}

	unfollow(blogName) {
		return this.del(this.url('/users/me/following'), { blogName })
	}

}
