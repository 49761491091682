import React, { Component } from 'react'
import Animator from './Animator'

export class Toast {

    constructor(component) {
      this.message = null
      this.animator = new Animator(0, 500, component) 
    }

    show(message) {
      this.message = message
      this.animator.play(1, () => this.animator.playAfter(2000, 0))
    } 

}

export class ToastWidget extends Component {

  render() {
    let props = this.props
    let toast = props.toast
    let message = toast.message
    let animator = toast.animator
    let animatorValue = animator.value
    return animatorValue ?
      (<div
        style={{
          position: 'absolute',
          padding: 10,
          backgroundColor: 'black',
          opacity: animatorValue,
          bottom: 0,
          left: 0,
          right: 0
        }}>
        <div
          style={{
            textAlign: 'center',
            color: 'white'
          }}>
          {message}
        </div>
      </div>) :
      null
  }

}
