import React from 'react'
import { Component } from 'react'
import './App.css'
import Server from './Server'
import TileWidget from './TileWidget'
import { ToastWidget, Toast } from './ToastWidget'
import { ActionButtonWidget, ActionButton } from './ActionButtonWidget'
import { ActionButtonGroupWidget, ActionButtonGroup } from './ActionButtonGroupWidget'
import Animator from './Animator'
import { 
  rgba, 
  def, 
  otherwise, 
  min, 
  max, 
  log, 
  report, 
  history, 
  historyHash, 
  nohash,
  localStorageBool,
  localStorageInt,
  setPath
} from './Util'
import Cookies from 'universal-cookie'
import queryString from 'query-string'
import { Stretch, Grow } from './Style'
import { 
  AppTextHeading, 
  AppTextP1, 
  AppTextP2, 
  AppFeedbackHeading, 
  AppFeedbackP1,
  AppVersionHeading,
  AppVersionP1 
} from './Strings'
import imgLogin from './img/login.svg'
import imgProfile from './img/profile.svg'
import imgWaiting from './img/waiting.svg'
import imgLogout from './img/logout.svg'
import imgTop from './img/top.svg'
import imgMinus from './img/minus.svg'
import imgPlus from './img/plus.svg'
import imgUp from './img/up.svg'
import imgFollow from './img/follow.svg'
import imgUnfollow from './img/unfollow.svg'
import imgLike from './img/like.svg'
import imgLiked from './img/liked.svg'
import imgQuickReblog from './img/quick-reblog.svg'
import imgQuickReblogged from './img/quick-reblogged.svg'
import imgReblog from './img/reblog.svg'
import imgDownload from './img/download.svg'
import imgLeft from './img/left.svg'
import imgRight from './img/right.svg'
import Persist from './Persist'
import SplashWidget from './SplashWidget'

function Shadow() {
  return ({
    height: 8,
    width: '100%',
    backgroundColor: rgba(0, 0, 0, 0.2),
    position: 'absolute',
    top: 0,
    left: 0
  })
}

function AppBackground() {
  return ({
    backgroundColor: '#ffff00'
  })
}

class Image {

  constructor(
    url, width, height, color, blogName, postId, reblogKey, liked) {
    this.url = url
    this.width = width
    this.height = height
    this.color = color
    this.blogName = blogName
    this.postId = postId
    this.reblogKey = reblogKey
    this.liked = liked
  }

}

class Tile {

  constructor(key, image, width, height) {
    this.key = key
    this.image = image
    this.width = width
    this.height = height
  }

}

class TileRow {

  constructor(key, offset, height, tiles) {
    this.key = key
    this.offset = offset
    this.height = height
    this.tiles = tiles
  }

}

let STATUS_NONE = 0
let STATUS_AUTH = 1
let STATUS_LOADING = 2
let STATUS_NOTHING_HERE = 3
let STATUS_THATS_ALL = 4

let _colors = [
  27, 40, 38, 26, 33, 35, 27, 25, 30, 34,
  22, 28, 31, 36, 24, 37, 40, 28, 30, 34,
  23, 29, 23, 30, 30, 39, 31, 33, 22, 23,
  25, 31, 25, 27, 23, 34, 23, 37, 38, 23,
  34, 26, 38, 29, 20, 25, 24, 38, 39, 27,
  24, 37, 26, 29, 23, 27, 23, 33, 30, 30,
  39, 21, 26, 34, 39, 31, 37, 34, 34, 24,
  25, 20, 36, 23, 38, 36, 22, 26, 29, 21,
  30, 23, 31, 30, 22, 39, 31, 34, 34, 23,
  35, 24, 36, 30, 39, 26, 32, 29, 23, 37
]

let STANDARD_TITLE = 'Cascadr | A Tumblr Image Viewer'
let MIN_RATIO = 1
let MAX_RATIO = 30
let DEFAULT_RATIO = 15
let RATIO_CURVE = 1.5
let HEADER_HEIGHT = 68
let TOP_CONTROLS_HEIGHT = 90
let LOAD_FACTOR = 5
let PAGE_SIZE = 50
let DASHBOARD = '<dash>'

////

export default class App extends Component {

  inputDelayMs = 500
  readAhead = 250
  readAheadHeight = 5000
  scrollRef = null

  _persist = new Persist()
  _blogParam = null
  _requestIndex = 0

  constructor(props) {
    let params = props.params
    super(props)
    let controlsOpen = localStorageBool('settings.controlsOpen', true)
    let zoomControlsOpen = localStorageBool('settings.zoomControlsOpen', true)
    let ratio = localStorageInt('settings.ratio', DEFAULT_RATIO)
    let scrollToTopActionButton = new ActionButton(this)
    let zoomInActionButton = new ActionButton(this)
    let zoomOutActionButton = new ActionButton(this)
    let followActionButton = new ActionButton(this)
    let actionButtonGroup = new ActionButtonGroup(
      this,
      [ scrollToTopActionButton, 
        zoomInActionButton, 
        zoomOutActionButton,
        followActionButton
      ],
      controlsOpen)
    let zoomLikeActionButton = new ActionButton(this)
    let zoomQuickReblogActionButton = new ActionButton(this)
    let zoomReblogActionButton = new ActionButton(this)
    let zoomDownloadActionButton = new ActionButton(this)
    let zoomActionButtonGroup = new ActionButtonGroup(
      this,
      [ zoomLikeActionButton, 
        zoomQuickReblogActionButton,
        zoomReblogActionButton, 
        zoomDownloadActionButton ],
      zoomControlsOpen)
    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      splash: true,
      controlsOpen,
      zoomControlsOpen,
      ratio,
      session: null,
      input: '',
      blog: '',
      baseBeforeId: '',
      savedBeforeId: '',
      requestIndex: 0,
      images: [],
      tileRows: [],
      tileRowsHeight: 0,
      tileKeyTileRows: new Map(),
      scrollTop: 0,
      status: STATUS_NONE,
      zoomVisible: new Animator(0, 200, this),
      zoomedImageIndex: null,
      zoomedImage: null,
      actionButtonGroup,
      scrollToTopActionButton,
      zoomInActionButton,
      zoomOutActionButton,
      followActionButton,
      zoomActionButtonGroup,
      zoomLikeActionButton, 
      zoomQuickReblogActionButton,
      zoomReblogActionButton, 
      zoomDownloadActionButton,
      toast: new Toast(this)
    }
    this._blogParam = def(params.blog)
    this._beforeIdParam = def(params.beforeId)
    this._server = new Server(process.env.REACT_APP_SERVER_URL)
  }

  componentDidMount() {
    window.addEventListener("resize", () => this._onResize())
    window.onpopstate = () => this._onBack()
    let leftReleased = true
    let rightReleased = true
    window.onkeydown = event => {
      log('=> Key down...')
      let key = event.keyCode
      log(key)
      let state = this.state
      if (state.zoomedImage) {
        let zoomedImageIndex = state.zoomedImageIndex
        if (leftReleased && key === 37) {
          leftReleased = false
          if (zoomedImageIndex > 0)
            this._onZoomLeft()
        }
        else if (rightReleased && key === 39) {
          rightReleased = false
          if (zoomedImageIndex < (state.images.length - 1))
            this._onZoomRight()
        }
      }
      else {
        if (key === 38 || key === 40 || key === 32) {
          this.scrollRef.current.focus()
        }
      }
    }
    window.onkeyup = event => {
      log('=> Key up...')
      let key = event.keyCode
      if (key === 37) leftReleased = true
      else if (key === 39) rightReleased = true
    }
    this.scrollRef = React.createRef()
  }

  componentWillUnmount() {
    this.state.zoomVisible.unmount()
  }

  // Utils

  _setUrl(blog, beforeId) {
    setPath(
      '/' + 
        (blog === DASHBOARD ? 'dash' : ('blogs/' + blog)) + 
        (beforeId === '' ? '' : ('/' + beforeId))) 
  }

  _saveBaseBeforeId(state, beforeId) {
    if (beforeId !== state.savedBeforeId) {
      log('Saving beforeId: ' + beforeId)
      let blog = state.blog
      this._persist.setBlogBeforeId(blog, beforeId)
      state.savedBeforeId = beforeId
      this._setUrl(blog, beforeId)
    }
  }

  _loadBaseBeforeId(state) {
    let blog = state.blog
    let baseBeforeId = this._persist.getBlogBeforeId(blog)
    if (baseBeforeId == null) baseBeforeId = ''
    log('Got stored beforeId for ' + blog + ': ' + baseBeforeId)
    state.baseBeforeId = baseBeforeId
    state.savedBeforeId = baseBeforeId
    this._setUrl(blog, baseBeforeId)
  }

  _topHeight(state) {
    return HEADER_HEIGHT + (state.baseBeforeId.length ? TOP_CONTROLS_HEIGHT : 0)
  }

  _topAdjust(state) {
    return Math.max(0, this._topHeight(state) - state.scrollTop)
  }

  _tileRowsScrollTop(state) {
    return state.scrollTop - this._topHeight(state)
  }

  _tileRowsScrollTo(state, scroll) {
    return scroll + this._topHeight(state)
  }

  _findTileRow(tileRows, i0, i1, scroll) {
    let n = i1 - i0
    if (n === 1) return tileRows[i0]
    else {
      let m = Math.floor(n / 2)
      let im = i0 + m
      let tileRow = tileRows[im]
      let offset = tileRow.offset
      return scroll < offset ?
        this._findTileRow(tileRows, i0, im, scroll) :
        this._findTileRow(tileRows, im, i1, scroll)
    }
  }

  _bestFitRow(tiles, offset, width) {
    var tiles1 = []
    var totalWidth = 0.0
    var i = offset
    var n = tiles.length
    var more = true
    while (more && i < n) {
      var tile = tiles[i]
      if (totalWidth + tile.width <= width) {
        tiles1.push(tile)
        totalWidth += tile.width
        i++
      }
      else more = false
    }
    if (tiles1.length === 0) tiles1.push(tiles[offset])
    return tiles1
  }

  _heightAdjustedTiles(images, height) {
    return images.map((it, i) =>
      new Tile(
        i,
        it,
        it.width * (height / it.height),
        height))
  }

  _rawRowWidth(tiles) {
    return tiles.reduce((previousValue, element) => previousValue + element.width, 0)
  }

  _tileRowKey(tiles) {
    return tiles.reduce((previousValue, element) =>
      previousValue === "" ? element.key : previousValue + "," + element.key, "")
  }

  _spread(tiles, totalWidth, verticalOffset, width, newHeight) {
    return new TileRow(
      this._tileRowKey(tiles),
      verticalOffset,
      newHeight,
      tiles.map((it) =>
        new Tile(
          it.key,
          it.image,
          (it.width / totalWidth) * width,
          newHeight)))
  }

  _doIfCurrent(requestIndex, f) {
    if (requestIndex !== this.state.requestIndex) log('Request out-dated')
    else f()
  }

  _clear(state) {
    state.images = []
    state.tileRows = []
    state.tileRowsHeight = 0
    state.tileKeyTileRows = {}
    state.baseBeforeId = ''
    state.zoomVisible.set(0)
    state.zoomedImage = null
    state.zoomedImageIndex = null
  }

  _loadBlog(state) {
    let requestIndex = state.requestIndex + 1
    this._server.getBlog(state.input)
      .then(({ ok, obj }) => {
        if (!ok) this._onBlogLoadFail()
        else this._doIfCurrent(requestIndex, () => this._onBlogLoad(obj))
      })
      .catch(e => this._onBlogLoadFail(e))
    this._clear(state)
    state.requestIndex = requestIndex
    state.status = STATUS_LOADING
  }

  _loadPage(state, beforeId) {
    let blog = state.blog
    let requestIndex = state.requestIndex + 1
    if (beforeId === '') beforeId = 0
    let promise = blog === DASHBOARD ?
      this._server.getDashImages(beforeId, PAGE_SIZE) :
      this._server.getBlogImages(blog, beforeId, PAGE_SIZE)
    promise
      .then(({ ok, obj }) => {
        if (!ok) this._onPageLoadFail()
        else this._doIfCurrent(requestIndex, () => this._onPageLoad(obj))
      })
      .catch(e => this._onPageLoadFail(e))
    state.requestIndex = requestIndex
    state.status = STATUS_LOADING
  }

  _loadFirstPage(state) {
    this._clear(state)
    this._loadPage(state, '')
  }

  _loadDashboard(state) {
    state.blog = DASHBOARD
    this._loadBaseBeforeId(state)
    this._loadPage(state, state.baseBeforeId)
  }

  _computeTileRows(state) {
    let images = state.images
    let windowWidth = state.windowWidth - 2
    let windowHeight = state.windowHeight
    let ratio = 1 + Math.pow(state.ratio / 10, RATIO_CURVE)
    let tileRowsHeight = 0
    let approxHeight = min(windowWidth / ratio, windowHeight / ratio)
    let heightAdjustedTiles = this._heightAdjustedTiles(images, approxHeight)
    let tileRows = []
    let tileKeyTileRows = new Map()
    let n = images.length
    let i = 0
    while (i < n) {
      let bestFitRow = this._bestFitRow(heightAdjustedTiles, i, windowWidth)
      let rawWidth1 = this._rawRowWidth(bestFitRow)
      let newHeight = approxHeight * (windowWidth / rawWidth1)
      let tileRow = this._spread(bestFitRow, rawWidth1, tileRowsHeight, windowWidth, newHeight)
      tileRows.push(tileRow)
      bestFitRow.forEach(tile => tileKeyTileRows.set(tile.key, tileRow))
      tileRowsHeight += newHeight
      i += bestFitRow.length
    }
    state.tileRows = tileRows
    state.tileRowsHeight = tileRowsHeight
    state.tileKeyTileRows = tileKeyTileRows
  }

  _toggleLiked(state) {
    let zoomedImage = state.zoomedImage
    let zoomLikeActionButton = state.zoomLikeActionButton
    if (zoomedImage.liked) zoomLikeActionButton.toggle()
    else zoomLikeActionButton.untoggle()
  }
  
  _zoomEither(state, direction) {
    let zoomedImageIndex = state.zoomedImageIndex + direction
    let zoomedImage = state.images[zoomedImageIndex]
    let scrollTop = state.scrollTop
    let windowHeight = state.windowHeight
    let scrollBottom = scrollTop + windowHeight
    let tileRow = state.tileKeyTileRows.get(zoomedImageIndex)
    let tileRowTop = this._tileRowsScrollTo(state, tileRow.offset)
    let tileRowHeight = tileRow.height
    let tileRowBottom = tileRowTop + tileRowHeight
    let scrollDiv = this.scrollRef.current
    if (tileRowTop < scrollTop)
      scrollDiv.scrollTo(0, tileRowTop)
    else if (tileRowBottom > scrollBottom)
      scrollDiv.scrollTo(0, tileRowTop - windowHeight + tileRowHeight)
    state.zoomedImageIndex = zoomedImageIndex
    state.zoomedImage = zoomedImage
    this._toggleLiked(state)
  }

  _loadInitial(state) {
    this._clear(state)
    if (state.input) this._loadBlog(state)
    else if (state.session) this._loadDashboard(state)
    else state.status = STATUS_NONE
  }

  _saveBaseBeforeIdForNextPost(state, image, index) {
    let images = state.images
    let postId = image.postId
    let postId0 = postId
    let i = index
    while (postId0 === postId && i > 0) {
      i--
      postId0 = images[i].postId
    }
    if (i < index) this._saveBaseBeforeId(state, postId0)
    else this._saveBaseBeforeId(state, state.baseBeforeId)
  }

  _openCloseControls(state, open) {
    state.controlsOpen = open
    localStorage.setItem('settings.controlsOpen', open)
  }

  _openCloseZoomControls(state, open) {
    state.zoomControlsOpen = open
    localStorage.setItem('settings.zoomControlsOpen', open)
  }

  _zoomInOut(state) {
    log('Zoom: ' + state.ratio)
    localStorage.setItem('settings.ratio', state.ratio)
    this._computeTileRows(state)
  }

  _zoomLikedUnliked(state, liked) {
    let images = state.images
    let image = state.zoomedImage
    let postId = image.postId
    let zoomedImageIndex = state.zoomedImageIndex
    let i = zoomedImageIndex
    let next = true
    while (next) { 
      image.liked = liked
      if (i > 0) {
        i--
        image = images[i]
        next = image.postId === postId
      }
      else next = false
    }
    i = zoomedImageIndex + 1
    let n = images.length - 1
    if (i <= n) {
      image = images[i]
      next = image.postId === postId
      while (next) { 
        image.liked = liked
        if (i < n) {
          i++
          image = images[i]
          next = image.postId === postId
        }
        else next = false
      }
    }
  }

  // Events

  async _onInit() {
    log('=> Initialise...')
    let state = this.state
    await this._persist.init()
    nohash()
    let blog = this._blogParam
    let beforeId = this._beforeIdParam
    if (beforeId)
      this._persist.setBlogBeforeId(otherwise(blog, DASHBOARD), beforeId)
    let params = this.props.location.search
    let session = def(queryString.parse(params).session)
    if (session) {
      localStorage.setItem('session', session)
      this._server.authorize(session)
      state.session = session
      this._loadDashboard(state)
    }
    else {
      session = localStorage.getItem('session')
      if (session) this._server.authorize(session)
      let input = otherwise(blog, '')
      log('Initialising with: ' + input)
      state.input = input
      state.session = session
      this._loadInitial(state)
    }
    this.setState(state)
  }

  _onReady() {
    log('=> Ready...')
    let state = this.state
    state.splash = false
    this.setState(state)
  }

  _onResize() {
    log('=> Resize...')
    let state = this.state
    state.windowWidth = window.innerWidth
    state.windowHeight = window.innerHeight
    this._computeTileRows(state)
    this.setState(state)
  }

  _onAuthenticate() {
    log('=> Authenticate...')
    let state = this.state
    this._server.getRequestToken()
      .then(({ ok, obj }) => {
        if (!ok) this._onAuthenticationFailed()
        else this._onAuthenticated(obj)
      })
      .catch(e => this._onAuthenticationFailed(e))
    state.status = STATUS_AUTH
    this.setState(state)
  }

  _onAuthenticationFailed(e) {
    log('=> Authentication failed...')
    let state = this.state
    report('Could not authenticate')
    if (e) report(e)
    state.status = STATUS_NONE
    this.setState(state)
  }

  _onAuthenticated(token) {
    log('=> Authenticated...')
    let state = this.state
    window.location.href =
      `https://www.tumblr.com/oauth/authorize?oauth_token=${token.requestToken}`
    this.setState(state)
  }

  _onLogout() {
    log('=> On logout...')
    let state = this.state
    new Cookies().remove('session')
    localStorage.removeItem('session')
    this._server.unauthorize()
    state.session = null
    if (state.blog === DASHBOARD) {
      state.blog = ''
      state.status = STATUS_NONE
      this._clear(state)
    }
    this.setState(state)
  }

  _onHome() {
    log('=> Home...')
    let state = this.state
    if (state.session) {
      history('/dash')
      state.blog = DASHBOARD
    }
    else {
      history('/')
      state.blog = ''
    }
    state.input = ''
    this._loadInitial(state)
    this.setState(state)
  }

  _onBack() {
    log('=> Back...')
    let state = this.state
    let path = window.location.pathname
    nohash()
    let blog = ''
    let beforeId = null
    let blogAndId = ''
    if (path.startsWith('/blogs/')) blogAndId = path.substring(7)
    else if (path.startsWith('/dash/')) blogAndId = path.substring(1)
    let idPos = blogAndId.indexOf('/')
    if (idPos !== -1) {
      blog = blogAndId.substring(0, idPos)
      beforeId = blogAndId.substring(idPos + 1)
    }
    else blog = blogAndId
    if (blog === 'dash') blog = DASHBOARD
    state.input = blog === DASHBOARD ? '' : blog
    state.blog = blog === DASHBOARD && !state.session ? '' : blog
    if (state.blog !== '' && beforeId) {
      state.baseBeforeId = beforeId
      this._saveBaseBeforeId(state, beforeId)
    }
    this._loadInitial(state)  
    this.setState(state)
  }

  _onInputChange(event) {
    log('>> Input change')
    let state = this.state
    let input = event.target.value.trim().toLowerCase()
    let matches = input.match(/(https?:\/\/)?(.+).tumblr.com(.*)/)
    input = matches && matches.length >= 3 ? matches[2] : input
    let timeout = state.timeout
    state.status = input || state.session ? STATUS_LOADING : STATUS_NONE
    if (timeout) clearTimeout(timeout)
    state.timeout = setTimeout(() => this._onInputConfirmed(input), 500)
    state.input = input
    this._clear(state)
    this.setState(state)
  }

  _onInputConfirmed() {
    log('=> Input confirmed...')
    let state = this.state
    this._loadInitial(state)
    this.setState(state)
  }

  _onBlogLoadFail(e) {
    log('=> Blog load fail...')
    let state = this.state
    report('Error getting blog')
    if (e) report(e)
    state.status = STATUS_NONE
    this.setState(state)
  }

  _onBlogEmpty() {
    log('=> Blog empty...')
    let state = this.state
    state.status = STATUS_NOTHING_HERE
    this.setState(state)
  }

  _onBlogLoad(blogObj) {
    log('=> Blog load...')
    let state = this.state
    if (blogObj === null) {
      state.status = STATUS_NOTHING_HERE
      historyHash('nothing')
      state.blog = ''
    }
    else {
      let blog = blogObj.name
      history('/blogs/' + blog)
      state.blog = blog
      state.mine = blogObj.mine
      let followActionButton = state.followActionButton
      if (blogObj.following) followActionButton.toggle()
      else followActionButton.untoggle()
      this._loadBaseBeforeId(state)
      this._loadPage(state, state.baseBeforeId)
    }
    this.setState(state)
  }

  _onPageLoadFail(e) {
    log('=> Page load fail...')
    let state = this.state
    report('Error getting page')
    if (e) report(e)
    state.status = STATUS_NONE
    this.setState(state)
  }

  _onPageLoad(imageObjs) {
    log('=> Page load...')
    let state = this.state
    let tileRowsHeight = state.tileRowsHeight
    let n = imageObjs.length
    if (n === 0) state.status = STATUS_THATS_ALL
    else {
      let images0 = state.images
      let images1 =
        imageObjs.map((it, i) =>
          new Image(
            it.url,
            it.width,
            it.height,
            _colors[i % _colors.length],
            it.blogName,
            it.postId,
            it.reblogKey,
            it.liked))
      let images = images0.concat(images1)
      state.images = images
      this._computeTileRows(state)
      if (state.scrollTop > tileRowsHeight - (state.windowHeight * LOAD_FACTOR)) {
        this._loadPage(state, images[images.length - 1].postId)
      }
      else state.status = STATUS_NONE
    }
    this.setState(state)
  }

  _onScroll(scrollTop) {
    log('=> Scroll... ')
    let state = this.state
    let images = state.images
    if (state.status !== STATUS_LOADING &&
        scrollTop > state.tileRowsHeight - (state.windowHeight * LOAD_FACTOR)) {
      this._loadPage(state, images[images.length - 1].postId)
    }
    state.scrollTop = scrollTop
    let tileRowsScrollTop = Math.max(0, this._tileRowsScrollTop(state))
    let tileRows = state.tileRows
    let n = tileRows.length
    if (n > 0) {
      let tileRow = this._findTileRow(tileRows, 0, n, tileRowsScrollTop)
      if (tileRow) {
        let tile = tileRow.tiles[0]
        this._saveBaseBeforeIdForNextPost(state, tile.image, tile.key)
      }
    }
    state.scrollTop = scrollTop
    this.setState(state)
  }

  _onGoToTop() {
    log('=> Go to top...')
    let state = this.state
    this._saveBaseBeforeId(state, '')
    this._loadFirstPage(state)
    this.setState(state)
  }

  _onScrollToTop() {
    this.scrollRef.current.scrollTo(0, 0)
  }

  _onOpenControls() {
    log('=> Open controls...')
    let state = this.state
    this._openCloseControls(state, true)
    this.setState(state)
  }

  _onCloseControls() {
    log('=> Close controls...')
    let state = this.state
    this._openCloseControls(state, false)
    this.setState(state)
  }

  _onOpenZoomControls() {
    log('=> Open zoom controls...')
    let state = this.state
    this._openCloseZoomControls(state, true)
    this.setState(state)
  }

  _onCloseZoomControls() {
    log('=> Close zoom controls...')
    let state = this.state
    this._openCloseZoomControls(state, false)
    this.setState(state)
  }

  _onZoomIn() {
    log('=> Zoom in...')
    let state = this.state
    let zoomInActionButton = state.zoomInActionButton
    let ratio = max(MIN_RATIO, Math.round(state.ratio - 1))
    state.ratio = ratio
    if (ratio <= MIN_RATIO) zoomInActionButton.disable()
    else zoomInActionButton.enable()
    state.zoomOutActionButton.enable()
    this._zoomInOut(state)
    this.setState(state)
  }

  _onZoomOut() {
    log('=> Zoom out...')
    let state = this.state
    let zoomOutActionButton = state.zoomOutActionButton
    let ratio = min(MAX_RATIO, Math.round(state.ratio + 1))
    state.ratio = ratio
    if (ratio >= MAX_RATIO) zoomOutActionButton.disable()
    else zoomOutActionButton.enable()
    state.zoomInActionButton.enable()
    this._zoomInOut(state)
    this.setState(state)
  }

  _onFollow() {
    log('=> Follow...')
    let state = this.state
    let blog = state.blog
    state.followActionButton.disable()
    this._server.follow(blog)
      .then(() => this._onFollowed())
      .catch(e => this._onFollowError(e))
    this.setState(state)
  }

  _onFollowed() {
    log('=> Followed...')
    let state = this.state
    state.following = true
    state.followActionButton.toggle()
    state.followActionButton.enable()
    this.setState(state)
  }

  _onFollowError(e) {
    log('=> Follow error...')
    let state = this.state
    state.followActionButton.enable()
    state.toast.show('Error')
    this.setState(state)
  }

  _onUnfollow() {
    log('=> Unfollow...')
    let state = this.state
    let blog = state.blog
    state.followActionButton.disable()
    this._server.unfollow(blog)
      .then(() => this._onUnfollowed())
      .catch(e => this._onUnfollowError(e))
    this.setState(state)
  }

  _onUnfollowed() {
    log('=> Unfollowed...')
    let state = this.state
    state.followActionButton.untoggle()
    state.followActionButton.enable()
    this.setState(state)
  }

  _onUnfollowError(e) {
    log('=> Unfollow error...')
    let state = this.state
    state.followActionButton.enable()
    state.toast.show('Error')
    this.setState(state)
  }

  _onZoomTile(tile) {
    log('=> Zoom tile...')
    let state = this.state
    let image = tile.image
    state.zoomVisible.play(1)
    state.zoomedImage = image
    state.zoomedImageIndex = tile.key
    state.zoomLikeActionButton.setEnabled()
    state.zoomLikeActionButton.setUntoggled()
    this._toggleLiked(state)
    state.zoomQuickReblogActionButton.setEnabled()
    state.zoomQuickReblogActionButton.setUntoggled()
    this.setState(state)
  }

  _onZoomToSource() {
    log('=> Zoom to source...')
    let state = this.state
    let zoomedImage = state.zoomedImage
    let zoomedImageIndex = state.zoomedImageIndex
    this._saveBaseBeforeIdForNextPost(state, zoomedImage, zoomedImageIndex)
    state.zoomVisible.set(0)
    state.zoomedImage = null
    state.input = zoomedImage.blogName
    this._loadBlog(state)
    this.setState(state)
  }

  _onZoomLeft() {
    log('=> Zoom left...')
    let state = this.state
    this._zoomEither(state, -1)
    this.setState(state)
  }

  _onZoomRight() {
    log('=> Zoom right...')
    let state = this.state
    this._zoomEither(state, 1)
    this.setState(state)
  }

  _onZoomDownload() {
    log('=> Zoom download...')
    let state = this.state
    let request = new XMLHttpRequest()
    request.open("GET", state.zoomedImage.url, true)
    request.responseType = "blob"
    request.onload = function () {
      let imageUrl = (window.URL || window.webkitURL).createObjectURL(this.response)
      let a = document.createElement('a')
      a.href = imageUrl
      a.download = ''
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
    request.send()
  }

  _onZoomLike() {
    log('=> Zoom like...')
    let state = this.state
    let zoomedImage = state.zoomedImage
    state.zoomLikeActionButton.disable()
    this._server.like(zoomedImage.postId, zoomedImage.reblogKey)
      .then(() => this._onZoomLiked())
      .catch(e => this._onZoomLikeError(e))
    this.setState(state)
  }

  _onZoomLiked() {
    log('=> Zoom liked...')
    let state = this.state
    this._zoomLikedUnliked(state, true)
    state.zoomLikeActionButton.toggle()
    state.zoomLikeActionButton.enable()
    this.setState(state)
  }

  _onZoomLikeError(e) {
    log('=> Zoom like error...')
    let state = this.state
    state.zoomLikeActionButton.enable()
    state.toast.show('Error')
    this.setState(state)
  }

  _onZoomUnlike() {
    log('=> Zoom unlike...')
    let state = this.state
    let zoomedImage = state.zoomedImage
    state.zoomLikeActionButton.disable()
    this._server.unlike(zoomedImage.postId, zoomedImage.reblogKey)
      .then(() => this._onZoomUnliked())
      .catch(e => this._onZoomUnlikeError(e))
    this.setState(state)
  }

  _onZoomUnliked() {
    log('=> Zoom unliked...')
    let state = this.state
    this._zoomLikedUnliked(state, false)
    state.zoomLikeActionButton.untoggle()
    state.zoomLikeActionButton.enable()
    this.setState(state)
  }

  _onZoomUnlikeError(e) {
    log('=> Zoom unlike error...')
    let state = this.state
    state.zoomLikeActionButton.enable()
    state.toast.show('Error')
    this.setState(state)
  }

  _onZoomQuickReblog() {
    log('=> Zoom quick reblog...')
    let state = this.state
    let zoomedImage = state.zoomedImage
    let zoomQuickReblogActionButton = state.zoomQuickReblogActionButton
    zoomQuickReblogActionButton.disable()
    this._server.reblog(zoomedImage.postId, zoomedImage.reblogKey)
      .then(() => this._onZoomQuickReblogged())
      .catch(e => this._onZoomQuickReblogError(e))
  }

  _onZoomQuickReblogged() {
    log('=> Zoom quick reblogged...')
    let state = this.state
    let zoomQuickReblogActionButton = state.zoomQuickReblogActionButton
    zoomQuickReblogActionButton.toggleThenUntoggle()
    this.setState(state)
  }

  _onZoomQuickReblogError(e) {
    log('=> Zoom quick reblog error...')
    let state = this.state
    state.zoomQuickReblogActionButton.enable()
    state.toast.show('Error')
    this.setState(state)
  }

  _onZoomReblog() {
    log('=> Zoom reblog...')
    let zoomedImage = this.state.zoomedImage
    window.open(
      'https://www.tumblr.com/login?redirect_to=%2Freblog%2F' +
      zoomedImage.postId +
      '%2F' +
      zoomedImage.reblogKey,
      '_blank')
  }

  _onCloseZoom() {
    log('=> Close zoom...')
    let state = this.state
    state.zoomVisible.play(0, () => this._onZoomClosed())
    this.setState(state)
  }

  _onZoomClosed() {
    log('=> Zoom closed...')
    let state = this.state
    state.zoomedImage = null
    state.zoomedImageIndex = null
    this.setState(state)
  }

  // View

  render() {
    let state = this.state
    let splash = state.splash
    let blog = state.blog
    let images = state.images
    let tileRows = state.tileRows
    let zoomVisible = state.zoomVisible
    let zoomedImage = state.zoomedImage
    let zoomedImageIndex = state.zoomedImageIndex
    let zoomedBlogName = zoomedImage ? zoomedImage.blogName : null
    let zoomedBlogLink = zoomedBlogName ? '/blogs/' + zoomedBlogName : null
    let zoomedImageWidth = 0
    let zoomedImageHeight = 0
    if (zoomVisible.value) {
      let w = zoomedImage.width
      let h = zoomedImage.height
      let r = h / w
      let ww = state.windowWidth
      let wh = state.windowHeight
      let wr = wh / ww
      if (r < wr) {
        zoomedImageWidth = ww
        zoomedImageHeight = ww * r
      }
      else {
        zoomedImageHeight = wh
        zoomedImageWidth = wh / r
      }
    }
    let status = state.status
    let session = state.session
    if (blog === '') document.title = STANDARD_TITLE 
    else if (blog === DASHBOARD) document.title = 'Dashboard'
    else document.title = blog
    let abws1 = session && blog !== DASHBOARD && !state.mine ? [ 
        <ActionButtonWidget
          image={imgFollow}
          toggleImage={imgUnfollow}
          actionButton={state.followActionButton}
          onClick={() => this._onFollow()}
          toggleOnClick={() => this._onUnfollow()}/>
      ] :
      []
    let abws2 = [
        <ActionButtonWidget
          image={imgUp}
          actionButton={state.scrollToTopActionButton}
          onClick={() => this._onScrollToTop()}/>,
        <ActionButtonWidget
          image={imgPlus}
          actionButton={state.zoomInActionButton}
          onClick={() => this._onZoomIn()}/>,
        <ActionButtonWidget
          image={imgMinus}
          actionButton={state.zoomOutActionButton}
          onClick={() => this._onZoomOut()}/>
      ]
    let zabws1 = [
        <ActionButtonWidget
          image={imgReblog}
          actionButton={state.zoomReblogActionButton}
          onClick={() => this._onZoomReblog()}/>,
        <ActionButtonWidget
          image={imgDownload}
          actionButton={state.zoomDownloadActionButton}
          onClick={() => this._onZoomDownload()}/>
      ]
    let zabws2 = session ? [
        <ActionButtonWidget
          image={imgLike}
          toggleImage={imgLiked}
          actionButton={state.zoomLikeActionButton}
          onClick={() => this._onZoomLike()}
          toggleOnClick={() => this._onZoomUnlike()}/>,
        <ActionButtonWidget
          image={imgQuickReblog}
          toggleImage={imgQuickReblogged}
          actionButton={state.zoomQuickReblogActionButton}
          onClick={() => this._onZoomQuickReblog()}/>
      ] :
      []
    return (splash ?
      <SplashWidget
        onInit={() => this._onInit()}
        onReady={() => this._onReady()}/> :
      <div className="App">
        <div ref={this.scrollRef}
          tabIndex="-1"
          className="AppScroll"
          onScroll={e => this._onScroll(e.target.scrollTop)}
          style={{ overflow: zoomedImage ? 'hidden' : 'auto' }}>
          <div className="AppMain">
            <div style={AppBackground()}>
              <div style={Stretch(0)}>
                <div style={Grow(0, 1)}>
                  <div className="AppLogoPad">
                    <div className="AppLogo"
                      onClick={() => this._onHome()} />
                  </div>
                </div>
                <div style={Grow(0, 0)}>
                  <div className="AppLogoPad">
                    {status === STATUS_AUTH ?
                      <ActionButtonWidget
                        className="AppHeaderButton AppHeaderWaitingButton"
                        image={imgWaiting}
                        onClick={() => {}}/> :
                      state.session ?
                        blog === DASHBOARD ?
                          <ActionButtonWidget
                            className="AppHeaderButton AppHeaderLogoutButton"
                            image={imgLogout}
                            onClick={() => this._onLogout()}/> :
                          <ActionButtonWidget
                            className="AppHeaderButton AppHeaderProfileButton"
                            image={imgProfile}
                            onClick={() => this._onHome()}/> :
                        <ActionButtonWidget
                          className="AppHeaderButton AppHeaderProfileButton"
                          image={imgLogin}
                          onClick={() => this._onAuthenticate()}/>}
                  </div>      
                </div>
              </div>
              <div style={Stretch(0)}>
                <div style={Grow(0, 1)}>
                  <div className="AppInputPad">
                    <input
                      className="AppInput"
                      type="text"
                      spellCheck="false"
                      placeholder="Type your blog name here"
                      value={state.input}
                      onChange={event => this._onInputChange(event)} />
                  </div>
                </div>
              </div>
            </div>
            {state.baseBeforeId.length ?
              <div className="AppTopControls">
                <div className="AppTopControlsCentre">
                  <ActionButtonWidget
                    className="AppTop"
                    image={imgTop}
                    onClick={() => this._onGoToTop()} />
                </div>
              </div> :
              null}
            {state.input || blog ?
              <div
                style={{
                  position: 'relative',
                  paddingTop: 2,
                  paddingLeft: 1,
                  paddingRight: 1,
                  paddingBottom: 1
                }}>
                <div className="AppTileRows">
                  {tileRows.map((tileRow) =>
                    <div key={tileRow.key} className="AppTileRow" style={{ height: tileRow.height }}>
                      {tileRow.tiles.map((tile) =>
                        <TileWidget
                          key={tile.key}
                          tile={tile}
                          height={state.height}
                          onClick={() => this._onZoomTile(tile)} />)
                      }
                    </div>
                  )}
                </div>
                {tileRows.length && !zoomVisible.max() ?
                  <ActionButtonGroupWidget
                    actionButtonGroup={state.actionButtonGroup}
                    top={this._topAdjust(state)}
                    onOpen={() => this._onOpenControls()}
                    onClose={() => this._onCloseControls()}
                    mainActionButtonWidgets={[...abws1, ...abws2]}/> :
                  null}
                {status === STATUS_NOTHING_HERE ?
                  <div className="AppInfo">Nothing here</div> :
                  (status === STATUS_THATS_ALL ?
                    <div className="AppInfo">That's all</div> :
                    (status === STATUS_LOADING ?
                      <div className="AppInfo">Loading</div> :
                      (status === STATUS_AUTH ?
                        <div className="AppInfo">Authenticating</div> :
                        null)))}
                <div style={Shadow()} />
              </div> :
              <div>
                <div className="AppText">
                  <h1>{AppTextHeading}</h1>
                  <p>{AppTextP1}</p>
                  <p>{AppTextP2}</p>
                </div>
                <div className="AppText">
                  <h1>{AppFeedbackHeading}</h1>
                  <p>
                    {AppFeedbackP1}
                    <a href="https://twitter.com/cascadr1"
                      target="_blank"
                      rel="noopener noreferrer">
                      @cascadr1
                    </a>.
                  </p>
                </div>
                <div className="AppText">
                  <h1>{AppVersionHeading}</h1>
                  <p>{AppVersionP1}</p>
                </div>
              </div>}
          </div>
        </div>
        {zoomVisible.value ?
          <div className="AppZoom"
            style={{opacity: zoomVisible.value}}>
            <div
              style={{
                position: 'absolute',
                inset: -20,
                backgroundImage: `url(${zoomedImage.url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter: 'brightness(0.3) blur(20px)'
              }}
              onClick={() => this._onCloseZoom()}/>
            <img 
              style={{
                position: 'absolute',
                width: zoomedImageWidth,
                height: zoomedImageHeight,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                boxShadow: 'rgb(0 0 0 / 0.6) 0px 0px 40px 20px'
              }} 
              src={zoomedImage.url}
              alt=""
              onClick={() => this._onCloseZoom()}/>
            {zoomedBlogName ?
              <div
                className="AppZoomSourceContainer">
                <ActionButtonWidget
                  className="AppZoomSource"
                  imageUrl={
                    'https://api.tumblr.com/v2/blog/' +
                    `${zoomedBlogName}.tumblr.com/avatar/64`}
                  onClick={() => this._onZoomToSource()}
                  link={zoomedBlogLink} />
              </div> :
              null}
            <ActionButtonGroupWidget
              actionButtonGroup={state.zoomActionButtonGroup}
              onOpen={() => this._onOpenZoomControls()}
              onClose={() => this._onCloseZoomControls()}
              mainActionButtonWidgets={session ? zabws2 : zabws1}
              secondaryActionButtonWidgets={session ? zabws1 : []}/>
            {zoomedImageIndex > 0 ?
              <div className="AppZoomMoveContainer AppZoomMoveContainerLeft">
                <ActionButtonWidget
                  className="AppZoomMoveLeft"
                  image={imgLeft}
                  onClick={() => this._onZoomLeft()} />
              </div> :
              null}
            {zoomedImageIndex < (images.length - 1) ?
              <div className="AppZoomMoveContainer AppZoomMoveContainerRight">
                <ActionButtonWidget
                  className="AppZoomMoveRight"
                  image={imgRight}
                  onClick={() => this._onZoomRight()} />
              </div>   :
              null}
          </div> :
          null}
          <ToastWidget toast={state.toast}/>
      </div>
    )
  }

}
